import { useEffect, useState } from 'react';
import { sendContact } from '../../../utility/FetchAPI';
import { emptyContactObject } from '../../../interfaces/IContact';
import useCountDown from 'react-countdown-hook';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { isbot } from 'isbot';
import {
    WbButton,
    WbControlError,
    WbControlHint,
    WbGrid,
    WbGridCol,
    WbGridRow,
    WbHeading,
    WbInput,
    WbText,
} from '@workbench/react';
import { WbInputCustomEvent } from '@workbench/core';
import { Theme, Box, Breadcrumbs, Link } from '@mui/material';
import { CheckCircleOutline, NavigateNext } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import useInput from '../../../hooks/useInput';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { FormattedMessage } from 'react-intl';
import { green, black, white, grey } from '@mercedes-benz/mui5-theme';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'grid',
        color: black,
        gap: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },
    formContainer: {
        display: 'grid',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
        backgroundColor: white,
        marginBottom: theme.spacing(12),
    },
    box: {
        backgroundColor: grey[90],
        height: '100%',
    },
    breadCrumbs: {
        marginTop: theme.spacing(4),
        color: black,
    },
    controlHint: {
        textAlign: 'right',
    },
    buttonsGroup: {
        display: 'inline-block',
    },
    buttonGap: {
        marginRight: theme.spacing(1.5),
    },
    notification: {
        backgroundColor: grey[90],
        padding: theme.spacing(2),
        color: green[45],
    },
}));

const inputEmailIsValid = (value: string) =>
    value
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null;

const inputTitleIsValid = (value: string) => value.trim() !== '';

const inputMessageIsValid = (value: string) => {
    if (value.trim() !== '' && value.length < 3000) {
        return true;
    } else return false;
};

const INITIAL_COUNTDOWN_TIME = 9000;
const TIMER_INTERVAL = 1000;
const MESSAGE_SYMBOL_LIMIT = 3000;

export default function Contact() {
    const { classes } = useStyles();
    let navigate = useNavigate();
    const [timeLeft, { start }] = useCountDown(INITIAL_COUNTDOWN_TIME, TIMER_INTERVAL);
    const [isBot, setIsBot] = useState<boolean>(false);
    const [showEmailSentFeedback, setShowEmailSentFeedback] = useState<boolean>(false);
    let formIsValid = false;

    const {
        input: enteredEmail,
        isValid: enteredEmailIsValid,
        hasError: enteredEmailHasError,
        inputChangeHandler: enteredEmailChangeHandler,
        inputBlurHandler: enteredEmailBlurHandler,
        reset: enteredEmailResetValues,
    } = useInput(inputEmailIsValid);

    const {
        input: enteredTitle,
        isValid: enteredTitleIsValid,
        hasError: enteredTitleHasError,
        inputChangeHandler: enteredTitleChangeHandler,
        inputBlurHandler: enteredTitleBlurHandler,
        reset: enteredTitleResetValues,
    } = useInput(inputTitleIsValid);

    const {
        input: enteredMessage,
        isValid: enteredMessageIsValid,
        hasError: enteredMessageHasError,
        inputChangeHandler: enteredMessageChangeHandler,
        inputBlurHandler: enteredMessageBlurHandler,
        reset: enteredMessageResetValues,
    } = useInput(inputMessageIsValid);

    if (enteredEmailIsValid && enteredTitleIsValid && enteredMessageIsValid) {
        formIsValid = true;
    }

    const inputMessageIsLimit = () => enteredMessage.length > MESSAGE_SYMBOL_LIMIT;

    const clearForm = () => {
        setShowEmailSentFeedback(true);
        enteredEmailResetValues();
        enteredTitleResetValues();
        enteredMessageResetValues();

        start();
        let interval = setInterval(() => {
            clearInterval(interval);
            navigate('/');
        }, INITIAL_COUNTDOWN_TIME);
    };

    const sendMail = () => {
        let contactData = emptyContactObject;
        contactData.email = enteredEmail;
        contactData.title = enteredTitle;
        contactData.message = enteredMessage;
        if (!isBot) {
            sendContact(contactData);
        }
        clearForm();
    };

    useEffect(() => {
        setIsBot(isbot(navigator.userAgent));
    }, []);

    const translate = useTranslation();

    return (
        <Box className={classes.box}>
            <WbGrid className={classes.container}>
                <Breadcrumbs
                    separator={<NavigateNext fontSize="small" />}
                    aria-label="breadcrumb"
                    className={classes.breadCrumbs}
                >
                    <Link to={'/'} color={black} underline="hover" component={RouterLink as any}>
                        <WbText size="m" strong>
                            {translate('Start')}
                        </WbText>
                    </Link>
                    <WbText size="m">{translate('Contact')}</WbText>
                </Breadcrumbs>
                <WbHeading size="l">{translate('WelcomeToSupport')}</WbHeading>
                <div>
                    <WbGridRow>
                        <WbGridCol mq1={12} mq2={12} mq3={12} mq4={6} mq5={6} mq6={6} className={classes.formContainer}>
                            {showEmailSentFeedback && (
                                <WbGridRow className={classes.notification}>
                                    <WbGridCol>
                                        <CheckCircleOutline />
                                    </WbGridCol>
                                    <WbGridCol mq1={8}>
                                        <WbText size="l" strong>
                                            {translate('SuccessMessage')}
                                        </WbText>
                                        <WbText size="m">
                                            <FormattedMessage
                                                id="MailFeedback"
                                                defaultMessage="MailFeedback"
                                                values={{ seconds: timeLeft / TIMER_INTERVAL }}
                                            />
                                        </WbText>
                                    </WbGridCol>
                                    <WbGridCol mq1={3}>
                                        <Link
                                            component="button"
                                            underline="hover"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate('/');
                                            }}
                                        >
                                            <WbText size="m">{translate('GoToHomepage')}</WbText>
                                        </Link>
                                    </WbGridCol>
                                </WbGridRow>
                            )}
                            <WbText strong>{translate('Email')}</WbText>
                            <WbInput
                                theme="light"
                                placeholder="max.muster@beispiel.com"
                                inputMode="email"
                                type="email"
                                value={enteredEmail}
                                aria-labelledby="EmailError"
                                onWbinput={(e: WbInputCustomEvent<{ name: string; value: string }>) =>
                                    enteredEmailChangeHandler(e)
                                }
                                onWbblur={enteredEmailBlurHandler}
                            >
                                <span slot="label">{translate('Email')}</span>
                                {enteredEmailHasError && (
                                    <WbControlError slot="message">{translate('ErrorEmail')}</WbControlError>
                                )}
                            </WbInput>

                            <WbText strong>{translate('Title')}</WbText>
                            <WbInput
                                theme="light"
                                type="text"
                                inputmode="text"
                                value={enteredTitle}
                                aria-labelledby="TitleError"
                                onWbinput={(e: WbInputCustomEvent<{ name: string; value: string }>) =>
                                    enteredTitleChangeHandler(e)
                                }
                                onWbblur={enteredTitleBlurHandler}
                            >
                                <span slot="label">{translate('Title')}</span>
                                {enteredTitleHasError && (
                                    <WbControlError slot="message">{translate('ErrorTitle')}</WbControlError>
                                )}
                            </WbInput>
                            <WbText strong>{translate('Message')}</WbText>
                            <WbInput
                                theme="light"
                                as="textarea"
                                value={enteredMessage}
                                aria-labelledby="MessageError"
                                onWbinput={(e: WbInputCustomEvent<{ name: string; value: string }>) =>
                                    enteredMessageChangeHandler(e)
                                }
                                onWbblur={enteredMessageBlurHandler}
                            >
                                <span slot="label">{translate('Message')}</span>
                                {!enteredMessageHasError && !inputMessageIsLimit() && (
                                    <WbControlHint slot="message" className={classes.controlHint}>
                                        {`${enteredMessage.length} / ${MESSAGE_SYMBOL_LIMIT}`}
                                    </WbControlHint>
                                )}
                                {(enteredMessageHasError || inputMessageIsLimit()) && (
                                    <WbControlError slot="message">
                                        {inputMessageIsLimit()
                                            ? translate('ErrorMessageLimit')
                                            : translate('ErrorMessage')}
                                    </WbControlError>
                                )}
                            </WbInput>
                            <div className={classes.buttonsGroup}>
                                <WbButton
                                    variant="secondary"
                                    className={classes.buttonGap}
                                    size="s"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/');
                                    }}
                                >
                                    {translate('Cancel')}
                                </WbButton>
                                <WbButton
                                    variant="primary"
                                    size="s"
                                    onClick={sendMail}
                                    {...(!formIsValid ? { disabled: true } : undefined)}
                                >
                                    {translate('Send')}
                                </WbButton>
                            </div>
                        </WbGridCol>
                    </WbGridRow>
                </div>
            </WbGrid>
        </Box>
    );
}
