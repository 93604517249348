import React, { memo, useMemo, useState } from 'react';
import useViewportWidth from '../../../hooks/useViewportWidth';
import { Divider } from '@mui/material';
import { IProductSheet } from '../../../interfaces/IProductSheet';
import { Document, Page, pdfjs } from 'react-pdf';
import { SupportedLanguages } from '../../../common/i18n/SupportedLanguages';
import { SkeletonSheetOverview } from './SkeletonSheetOverview';
import { WbText } from '@workbench/react';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { useTranslationContext } from '../../../common/i18n/TranslationProvider';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

interface SheetPdfViewerProps {
    sheet: IProductSheet;
    onError: () => void;
}

const HORIZONTAL_MARGIN_MOBILE = 100;
const HORIZONTAL_MARGIN_TABLET = 300;
const MOBILE = 1000;
const DESKTOP = 1780;
const MAX_WIDTH = 1600;

function SheetPdfViewer(props: SheetPdfViewerProps) {
    const [numPages, setNumPages] = useState<number>();
    const mediaWidth = useViewportWidth();
    const [isLoadingfile, setIsLoadingfile] = useState<boolean>(true);
    const translate = useTranslation();
    const { langState } = useTranslationContext();

    const calculateWidth = () => {
        if (mediaWidth < MOBILE) {
            return mediaWidth - HORIZONTAL_MARGIN_MOBILE;
        } else if (mediaWidth < DESKTOP) {
            return mediaWidth - HORIZONTAL_MARGIN_TABLET;
        } else {
            return MAX_WIDTH;
        }
    };

    const width = calculateWidth();

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
        setIsLoadingfile(false);
    };

    const onDocumentLoadError = () => {
        setIsLoadingfile(false);
        props.onError();
    };

    const url = langState === SupportedLanguages.de ? props?.sheet?.pdfLinkDe : props?.sheet?.pdfLinkEn;

    // const url = window.location.origin + '/test-pdf.pdf';

    const file = useMemo(() => ({ url }), [url]);

    return (
        <>
            {isLoadingfile && <SkeletonSheetOverview file />}

            <Document
                file={file}
                onLoadError={onDocumentLoadError}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={null}
                error={<WbText style={{ color: 'red' }}>{translate('error_loading_pdf_file')}</WbText>}
            >
                {Array.from(new Array(numPages), (_, i) => (
                    <React.Fragment key={`page_${i + 1}`}>
                        <Page pageNumber={i + 1} renderTextLayer={false} renderAnnotationLayer={false} width={width} />
                        <Divider sx={{ height: '20px' }} />
                    </React.Fragment>
                ))}
            </Document>
        </>
    );
}

export default memo(SheetPdfViewer);
