import { WbButton, WbModal, WbModalLevel } from '@workbench/react';
import { useTranslation } from '../../../common/i18n/useTranslation';

interface DialogProps {
    handleClose: () => void;
    handleConfirm: () => void;
    header: string;
    content: string;
}

export function CustomDialog(props: DialogProps) {
    const translate = useTranslation();

    return (
        <WbModal variant="prompt" size="m" modalAriaLabel="Dialog">
            <WbModalLevel
                headline={props.header}
                showBackButton={false}
                showCloseButton={false}
                closeButtonLabel="close dialog"
            >
                {props.content}
                <WbButton slot="footer" type="button" variant="secondary" onClick={props.handleClose}>
                    {translate('Cancel')}
                </WbButton>
                {props.handleConfirm && (
                    <WbButton slot="footer" type="button" variant="primary" onClick={props.handleConfirm}>
                        {translate('Yes')}
                    </WbButton>
                )}
            </WbModalLevel>
        </WbModal>
    );
}
