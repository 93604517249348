import { WbGridCol, WbGridRow, WbHeading } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { useTranslation } from '../../../common/i18n/useTranslation';
import SearchResultNotification from './SearchResultNotification';
import SearchResultTable from './SearchResultTable';
import { FuelApprovalDto } from '../../../api/models/FuelApprovalDto';
import { white } from '@mercedes-benz/mui5-theme';

const useStyles = makeStyles()((theme: Theme) => ({
    whiteColor: {
        color: white,
    },
    elementSpacing: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));

interface SearchResultProps {
    hasData?: boolean | null;
    fuelApproval?: FuelApprovalDto | string;
}

const SearchResult = ({ hasData, fuelApproval }: SearchResultProps) => {
    const translate = useTranslation();
    const { classes } = useStyles();

    return (
        <>
            {hasData !== undefined && hasData !== null && (
                <WbGridRow>
                    <WbGridCol mq1={12} mq2={12} mq3={12} mq4={12} mq5={6} mq6={6} className={classes.elementSpacing}>
                        <WbHeading size="l" className={classes.whiteColor}>
                            {translate('FluidFinderFinSearchResult')}
                        </WbHeading>
                        <SearchResultNotification hasContent={hasData} />
                        {hasData && <SearchResultTable fuelApproval={fuelApproval as FuelApprovalDto} />}
                    </WbGridCol>
                </WbGridRow>
            )}
        </>
    );
};

export default SearchResult;
