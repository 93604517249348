import { useState, useEffect } from 'react';
import '../../../../../common/style/GlobalStyle.css';
import { getSheetNumbersByCategory } from '../../../../../utility/FetchAPI';
import { CATEGORY_PROPS, CategoryType } from '../../../../../common/Constants';
import { useNavigate } from 'react-router-dom';
import {
    WbHeaderButton,
    WbHeaderDesktopFlyIn,
    WbHeaderFlyInContainer,
    WbHeaderFlyInItem,
    WbHeaderLanguageMenu,
    WbHeaderMobileFlyIn,
    WbIcon,
    WbText,
} from '@workbench/react';
import { useCategoryMenuContext } from '../../../../category/context/CategoryContext';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { IProductSheet } from '../../../../../interfaces/IProductSheet';
import LanguageSelector from '../../../../../common/i18n/LanguageSelector';
import { useTranslation } from '../../../../../common/i18n/useTranslation';
import I18nTexts from '../../../../../common/i18n/texts/I18nTexts';
import { useScrollLockContext } from '../../../../../utility/ScrollLockContext';
import { NavigationItems } from '../../Header';
import { grey } from '@mercedes-benz/mui5-theme';

const useStyles = makeStyles()((theme: Theme) => ({
    categoryIcon: {
        ['--size' as string]: theme.spacing(6),
        color: grey[40],
        marginRight: theme.spacing(2),
    },
    arrowIcon: {
        alignSelf: 'center',
        ['--size' as string]: theme.spacing(2),
    },
    menuText: {
        alignSelf: 'center',
    },
}));

export default function CategoriesMenu() {
    const translate = useTranslation();
    const { classes } = useStyles();
    const navigate = useNavigate();
    const [choosenCategory, setChoosenCategory] = useState<CategoryType>({} as CategoryType);
    const [categoryLink, setCategoryLink] = useState('');
    const { categorySheetCollection, updateCategorySheets, updateFlyInContainerOpen, updatePreviouslyActiveNavItem } =
        useCategoryMenuContext();
    const { unlockScroll } = useScrollLockContext();

    let flyInToggles = document.querySelectorAll('[toggles-fly-in]') as any;
    let flyIns = document.querySelectorAll('wb-header-desktop-fly-in, wb-header-mobile-fly-in') as any;
    let flyInContainer = document.querySelector('wb-header-fly-in-container') as any;

    useEffect(() => {
        const timeout = setTimeout(() => {
            initdocmentElements();
            addEventListeners();
        }, 0);

        return () => clearTimeout(timeout);
    }, []);

    const initdocmentElements = () => {
        flyInToggles = document.querySelectorAll('[toggles-fly-in]') as any;
        flyIns = document.querySelectorAll('wb-header-desktop-fly-in, wb-header-mobile-fly-in') as any;
        flyInContainer = document.querySelector('wb-header-fly-in-container') as any;
    };

    const categorieClickedHandler = (
        e: React.MouseEvent<HTMLWbHeaderFlyInItemElement, MouseEvent>,
        category: CategoryType
    ) => {
        e.preventDefault();
        chooseCategory(category);
        setCategoryLink(category.link);
    };

    const addEventListeners = () => {
        // handle wbclose events emitted when a close button or the ESC key was clicked
        document.addEventListener('wbclose', (e: any) => {
            // unlock scrolling
            unlockScroll();
            const targetTagName = e.target.tagName.toLowerCase();
            const headerElements = [
                'wb-header-desktop-fly-in',
                'wb-header-mobile-fly-in',
                'wb-header-fly-in-container',
            ];
            if (headerElements.includes(targetTagName)) {
                flyIns.forEach((flyIn: any) => (flyIn.open = false));
                if (flyInContainer) {
                    flyInContainer.open = false;
                }
                unsetActiveCategoryToggles();
                unsetActiveNavigationItem();
            }

            updateFlyInContainerOpen(false);
            updatePreviouslyActiveNavItem(NavigationItems.NOTHING);
        });

        // handle wbback events emitted when a close button or the ESC key was clicked
        flyIns.forEach((flyIn: any) => {
            flyIn.addEventListener('wbback', () => {
                flyIn.open = false;
                unsetActiveCategoryToggles();
            });
        });

        // handle category menu item click event
        flyInToggles.forEach((toggle: any) => {
            toggle.addEventListener('click', () => {
                unsetActiveCategoryToggles();
                const flyInIdentifier = toggle.getAttribute('toggles-fly-in');
                const flyInsToToggle = Array.from(flyIns).filter((flyIn: any) =>
                    flyIn.classList.contains(flyInIdentifier)
                );

                flyInsToToggle.forEach((flyInToToggle: any) => {
                    const newOpenState = !flyInToToggle.open;

                    if (newOpenState) {
                        flyInContainer.open = true; // make sure fly-in container is also open
                    }

                    if (isLastOpen() && !newOpenState) {
                        flyInContainer.open = false; // close the container if the first level closes
                    }
                    if (flyInToToggle.level === 1) {
                        flyInToToggle.open = newOpenState;
                    }
                    if (flyInToToggle.level === 2 && !flyInToToggle.open) {
                        flyInToToggle.open = newOpenState;
                    }
                });

                toggle.toggleAttribute('active');
            });
        });
    };

    const openLearnMore = (categoryLink: string) => {
        closeCategoriesMenu();
        unlockScroll();
        updateFlyInContainerOpen(false);
        updatePreviouslyActiveNavItem(NavigationItems.NOTHING);
        unsetActiveNavigationItem();
        navigate(`/category${categoryLink}`);
    };

    const showPdf = (product: IProductSheet, navigate: any) => {
        closeCategoriesMenu();
        unlockScroll();
        updateFlyInContainerOpen(false);
        updatePreviouslyActiveNavItem(NavigationItems.NOTHING);
        unsetActiveNavigationItem();

        navigate(`/sheet/${product?.sheetNumber}`);
    };

    // fetch sheets from the API
    const chooseCategory = (category: CategoryType) => {
        getSheetNumbersByCategory(category.description).then((result) => {
            updateCategorySheets(result.data);
            setChoosenCategory(category);
        });
    };

    function isLastOpen() {
        return Array.from(flyIns).filter((flyIn: any) => flyIn.open).length === 1;
    }

    function unsetActiveCategoryToggles() {
        flyInToggles.forEach((toggle: any) => {
            if (!toggle.classList.contains('wb-header-navigation-item')) {
                toggle.removeAttribute('active');
            }
        });
    }

    function unsetActiveNavigationItem() {
        flyInToggles.forEach((toggle: any) => {
            if (toggle.classList.contains('wb-header-navigation-item')) {
                toggle.removeAttribute('active');
            }
            updateFlyInContainerOpen(false);
        });
    }

    const closeCategoriesMenu = () => {
        const flyInToggles = document.querySelectorAll('[toggles-fly-in]') as any;
        const flyIns = document.querySelectorAll('wb-header-desktop-fly-in, wb-header-mobile-fly-in') as any;
        flyInToggles.forEach((toggle: any) => {
            const flyInIdentifier = toggle.getAttribute('toggles-fly-in');
            const flyInsToToggle = Array.from(flyIns).filter((flyIn: any) => flyIn.classList.contains(flyInIdentifier));
            flyInsToToggle.forEach((flyInToToggle: any) => {
                flyInToToggle.open = false;
            });
        });

        flyInContainer.open = false;

        unlockScroll();
    };

    return (
        <WbHeaderFlyInContainer>
            <WbHeaderMobileFlyIn
                class="level-1"
                level={1}
                headline={translate('Categories')}
                show-star
                star-href="/"
                star-label="Go to homepage"
            >
                <WbHeaderButton slot="top-bar-end" toggles-fly-in="level-1" label="Close fly-in level 1">
                    <WbIcon name="bds/close/24"></WbIcon>
                </WbHeaderButton>

                {CATEGORY_PROPS.map((category) => {
                    return (
                        <WbHeaderFlyInItem
                            key={category.description}
                            toggles-fly-in="level-2"
                            onClick={(e) => categorieClickedHandler(e, category)}
                        >
                            <WbIcon src={category.icon} className={classes.categoryIcon} />
                            <WbText size="l" className={classes.menuText}>
                                {translate(category.description as keyof I18nTexts)}
                            </WbText>
                            <WbIcon slot="icon" name="chevron-right" className={classes.arrowIcon} flip-rtl></WbIcon>
                        </WbHeaderFlyInItem>
                    );
                })}
                <WbHeaderLanguageMenu slot="footer" aria-label="Language Menu">
                    <LanguageSelector />
                </WbHeaderLanguageMenu>
            </WbHeaderMobileFlyIn>
            <WbHeaderMobileFlyIn
                class="level-2"
                level={2}
                headline={choosenCategory.description ? translate(choosenCategory.description as keyof I18nTexts) : ''}
                back-button-label="Back"
                close-button-label="Close"
                show-back-button
                show-close-button
            >
                <WbHeaderFlyInItem onClick={() => openLearnMore(categoryLink)}>
                    {translate('LearnMore')}
                </WbHeaderFlyInItem>
                {categorySheetCollection?.map((product) => {
                    return (
                        <WbHeaderFlyInItem
                            key={product.sheetNumber + product.category}
                            onClick={() => showPdf(product, navigate)}
                        >
                            {product.sheetNumber}
                        </WbHeaderFlyInItem>
                    );
                })}
                <WbHeaderLanguageMenu slot="footer" aria-label="Language Menu">
                    <LanguageSelector />
                </WbHeaderLanguageMenu>
            </WbHeaderMobileFlyIn>

            <WbHeaderDesktopFlyIn
                class="level-1"
                level={1}
                headline={translate('Categories')}
                back-button-label="Back"
                close-button-label="Close"
            >
                {CATEGORY_PROPS.map((category) => {
                    return (
                        <WbHeaderFlyInItem
                            key={category.description}
                            toggles-fly-in="level-2"
                            onClick={(e) => categorieClickedHandler(e, category)}
                        >
                            <WbIcon src={category.icon} className={classes.categoryIcon} />
                            <span className={classes.menuText}>
                                {translate(category.description as keyof I18nTexts)}
                            </span>
                            <WbIcon slot="icon" name="chevron-right" className={classes.arrowIcon} flip-rtl></WbIcon>
                        </WbHeaderFlyInItem>
                    );
                })}
            </WbHeaderDesktopFlyIn>
            <WbHeaderDesktopFlyIn
                class="level-2"
                level={2}
                headline={
                    choosenCategory?.description ? translate(choosenCategory?.description as keyof I18nTexts) : ''
                }
                back-button-label="Back"
                close-button-label="Close"
            >
                <WbHeaderFlyInItem onClick={() => openLearnMore(categoryLink)}>
                    {translate('LearnMore')}
                </WbHeaderFlyInItem>
                {categorySheetCollection?.map((product) => {
                    return (
                        <WbHeaderFlyInItem
                            key={product.sheetNumber + product.category}
                            onClick={() => showPdf(product, navigate)}
                        >
                            {product.sheetNumber}
                        </WbHeaderFlyInItem>
                    );
                })}
            </WbHeaderDesktopFlyIn>
        </WbHeaderFlyInContainer>
    );
}
