import { WbTable, WbTableCell, WbTableHeader, WbTableRow } from '@workbench/react';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { makeStyles } from 'tss-react/mui';
import { FuelApprovalDto } from '../../../api/models/FuelApprovalDto';

const useStyles = makeStyles()(() => ({
    table: {
        width: '100%',
    },
}));

interface SearchResultTableProps {
    fuelApproval?: FuelApprovalDto;
}

const SearchResultTable = ({ fuelApproval }: SearchResultTableProps) => {
    const translate = useTranslation();
    const { classes } = useStyles();

    const formatEngineText = (engine?: string) => {
        if (engine?.length === 8) {
            return `${engine.substring(0, 2)} ${engine.substring(2, 5)}.${engine.substring(5, 8)}`;
        }

        return engine;
    };
    const formatPerformanceText = (power?: string) => {
        if (power) {
            return `${power.substring(0, power.indexOf('.'))} ${power.substring(power.length - 2, power.length)}`;
        }

        return power;
    };

    const isValueNull = (value?: string) => {
        if (value === undefined) return true;
        return value?.includes('null');
    };

    return (
        <WbTable striped theme="dark" className={classes.table}>
            <WbTableRow>
                <WbTableHeader>{translate('FluidFinderVin')}</WbTableHeader>
                <WbTableCell className={classes.table}>{fuelApproval?.fin}</WbTableCell>
            </WbTableRow>
            {!isValueNull(fuelApproval?.brand) && (
                <WbTableRow>
                    <WbTableHeader>{translate('FluidFinderModel')}</WbTableHeader>
                    <WbTableCell className={classes.table}>{fuelApproval?.brand}</WbTableCell>
                </WbTableRow>
            )}
            <WbTableRow>
                <WbTableHeader>{translate('FluidFinderSeries')}</WbTableHeader>
                <WbTableCell className={classes.table}>{fuelApproval?.series}</WbTableCell>
            </WbTableRow>
            <WbTableRow>
                <WbTableHeader>{translate('FluidFinderEngine')}</WbTableHeader>
                <WbTableCell className={classes.table}>{formatEngineText(fuelApproval?.engine)}</WbTableCell>
            </WbTableRow>
            {!isValueNull(fuelApproval?.power) && (
                <WbTableRow>
                    <WbTableHeader>{translate('FluidFinderPerformance')}</WbTableHeader>
                    <WbTableCell className={classes.table}>{formatPerformanceText(fuelApproval?.power)}</WbTableCell>
                </WbTableRow>
            )}
        </WbTable>
    );
};

export default SearchResultTable;
