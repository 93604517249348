import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { emptyProductSheetObject } from '../../interfaces/IProductSheet';
import { getProductsById } from '../../utility/FetchAPI';
import { SheetTable } from '../category/sheetPage/SheetTable';
import moment from 'moment';
import { IProduct } from '../../interfaces/IProduct';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { WbGrid, WbHeading, WbText } from '@workbench/react';
import { useTranslationContext } from '../../common/i18n/TranslationProvider';
import { useTranslation } from '../../common/i18n/useTranslation';
import { SupportedLanguages } from '../../common/i18n/SupportedLanguages';
import I18nTexts from '../../common/i18n/texts/I18nTexts';
import { white, grey } from '@mercedes-benz/mui5-theme';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        marginTop: theme.spacing(4),
    },
    loading: {
        color: white,
    },
    title: {
        color: white,
        marginBottom: theme.spacing(5),
    },
    category: {
        color: grey[50],
        float: 'left',
    },
    lastUpdate: {
        color: white,
        float: 'right',
    },
    approval: {
        color: grey[50],
    },
}));

export const QrOverview = () => {
    //Get data in Use Effect
    const [productSheet, setProductSheet] = useState(emptyProductSheetObject);
    const [sheetNumbers, setSheetNumbers] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { productId } = useParams();
    const { classes } = useStyles();
    const { langState } = useTranslationContext();
    const translate = useTranslation();

    useEffect(() => {
        let numbers: string[] = [];
        let resultSheet = emptyProductSheetObject;
        getProductsById(productId!).then((result: any) => {
            if (typeof result.data[0] !== 'undefined') {
                setProductSheet(result.data[0]);

                let foundProducts: IProduct[] = [];

                resultSheet = result.data[0];
                result.data.map((productSheet: any) => {
                    numbers.push(productSheet.sheetNumber);
                    numbers.sort((a, b) => (a < b ? -1 : 1));
                    setSheetNumbers(numbers);
                    let filteredProducts = productSheet.products.filter(
                        (product: any) => product.productId === productId
                    );
                    foundProducts.push(...filteredProducts);
                    return foundProducts;
                });

                resultSheet.products = [foundProducts[foundProducts.length - 1]];

                setProductSheet(resultSheet);
            }
            setIsLoading(false);
        });
    }, [productId]);

    const getFormattedDate = () => {
        return langState === SupportedLanguages.de
            ? moment(new Date(productSheet.publishDate).toISOString()).format('DD.MM.YYYY')
            : moment(new Date(productSheet.publishDate).toISOString()).format('MM/DD/YYYY');
    };

    const getAllSheetNumbersAsStr = () => {
        let sheetNumberStr: string = '';
        sheetNumbers.forEach((sheetNumber: string, index: number) => {
            if (sheetNumbers.length > index + 1) {
                sheetNumberStr += sheetNumber + ' / ';
            } else {
                sheetNumberStr += sheetNumber;
            }
        });

        return sheetNumberStr;
    };

    let content = <></>;

    if (!isLoading) {
        if (sheetNumbers.length < 1) {
            content = (
                <WbHeading size="l" className={classes.title}>
                    {translate('ProductNotApproved')}
                </WbHeading>
            );
        } else {
            content = (
                <>
                    <WbHeading size="l" className={classes.title}>
                        {translate('ProductApproval')}
                    </WbHeading>
                    <WbText size="l" strong className={classes.category}>
                        {translate('Category')}: {translate(productSheet.category as keyof I18nTexts)}
                    </WbText>
                    <WbText className={classes.lastUpdate}>
                        {translate('LastUpdate')} {getFormattedDate()}
                    </WbText>
                    <SheetTable sheet={productSheet} />
                    <WbText className={classes.approval}>
                        {`${translate('MbApprovalLabel')}: `} {getAllSheetNumbersAsStr()}
                    </WbText>
                </>
            );
        }
    }

    return <WbGrid className={classes.container}>{content}</WbGrid>;
};
