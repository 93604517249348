import vehicleSkeletonImage from '../../../../assets/vehicleSkeleton.jpg';
import IconNavigation from './IconNavigation';
import { WbAspectRatio, WbGrid, WbHeading, WbStage } from '@workbench/react';
import { Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ isBig: boolean; isMobile: boolean }>()((theme: Theme, { isBig, isMobile }) => ({
    engineNavigator: {
        top: isBig ? '53%' : '49%',
        left: '30%',
        position: 'absolute',
        zIndex: 1, //otherwise you cant interact with it (wbstage intern stuffs)
    },
    gearNavigator: {
        top: isBig ? '57%' : '54%',
        left: '48%',
        position: 'absolute',
        zIndex: 1,
    },
    adBlueNavigator: {
        top: '21%',
        left: '65%',
        position: 'absolute',
        zIndex: 1,
    },
    coolantNavigator: {
        top: isBig ? '70%' : '68%',
        left: '29%',
        position: 'absolute',
        zIndex: 1,
    },
    FuelsNavigator: {
        top: '21%',
        left: isBig ? '63%' : isMobile ? '59%' : '61%',
        position: 'absolute',
        zIndex: 1,
    },
    title: {
        position: 'absolute',
        padding: theme.spacing(6),
        top: 0,

        '@media (max-width: 1024px)': {
            position: 'unset',
            padding: 'unset',
        },
    },
}));

export default function VehicleSkeletonHeader() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isBig = useMediaQuery('(min-width:1000px)');
    const { classes } = useStyles({ isBig, isMobile });

    return (
        <WbGrid>
            <WbStage theme="dark">
                <WbAspectRatio slot="media" ratio="9x4">
                    <img src={vehicleSkeletonImage} alt="vehicle skeleton" />
                    <div className={classes.engineNavigator}>
                        <IconNavigation description="EngineOil" link={'/category/engine-oil'} position="top" />
                    </div>
                    <div className={classes.gearNavigator}>
                        <IconNavigation description="GearOil" link={'/category/gear-oil'} position="top" />
                    </div>
                    <div className={classes.adBlueNavigator}>
                        <IconNavigation description="AdBlue" link={'/category/NOx-reducing-agent'} position="top" />
                    </div>
                    <div className={classes.coolantNavigator}>
                        <IconNavigation description="Coolant" link={'/category/coolant'} position="bottom" />
                    </div>
                    <div className={classes.FuelsNavigator}>
                        <IconNavigation description="Fuels" link={'/category/fuel'} position="top" />
                    </div>
                </WbAspectRatio>
                <div className={classes.title}>
                    <WbHeading tag="h2" size="l">
                        Mercedes-Benz
                    </WbHeading>
                    <WbHeading tag="h2" size="l">
                        Operating Fluids
                    </WbHeading>
                </div>
            </WbStage>
        </WbGrid>
    );
}
