import adBlueImage from '../../../assets/adBlue.jpg';
import gearImage from '../../../assets/gear.jpg';
import coolantImage from '../../../assets/coolant.jpg';
import engineImage from '../../../assets/engine.jpg';
import { makeStyles } from 'tss-react/mui';
import { WbGrid, WbGridCol, WbGridRow, WbHeading, WbText } from '@workbench/react';
import { Theme } from '@mui/material';
import CategoryListTeaser from './CategoryListTeaser';
import { CategoryDescription } from '../../../common/Constants';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { white } from '@mercedes-benz/mui5-theme';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'grid',
        gap: theme.spacing(2),
        width: '100%',
        color: white,
    },
    divider: {
        margin: 0,
        padding: 0,
        borderBottom: '1px solid #fff',
        width: theme.spacing(5),
    },
    header: {
        display: 'grid',
        gap: theme.spacing(2),
    },
}));

type InterestedCategories = {
    image: string;
    url: string;
    title: string;
    description: string;
};

interface CategoryListProps {
    description: CategoryDescription;
}

export default function CategoryInterested(props: CategoryListProps) {
    const { classes } = useStyles();
    const translate = useTranslation();

    const KatList: InterestedCategories[] = [
        {
            image: engineImage,
            url: '/category/engine-oil/',
            title: translate('EngineOil'),
            description: translate('EngineOil'),
        },
        {
            image: gearImage,
            url: '/category/gear-oil/',
            title: translate('GearOil'),
            description: translate('GearOil'),
        },
        {
            image: coolantImage,
            url: '/category/coolant/',
            title: translate('Coolant'),
            description: translate('Coolant'),
        },
        {
            image: adBlueImage,
            url: '/category/NOx-reducing-agent/',
            title: translate('AdBlue'),
            description: translate('AdBlue'),
        },
    ];
    return (
        <div className={classes.container}>
            <WbGrid>
                <WbGridCol className={classes.header}>
                    <WbText size="l">{translate('Interested')}</WbText>
                    <hr className={classes.divider} />
                    <WbHeading size="l">{translate('TopCategories')}</WbHeading>
                </WbGridCol>
            </WbGrid>

            <WbGrid>
                <WbGridRow>
                    {props.description !== CategoryDescription.ENGINEOIL && (
                        <CategoryListTeaser
                            url={KatList[0].url}
                            image={KatList[0].image}
                            description={KatList[0].description}
                            title={KatList[0].title}
                        />
                    )}
                    {props.description !== CategoryDescription.GEAROIL && (
                        <CategoryListTeaser
                            url={KatList[1].url}
                            image={KatList[1].image}
                            description={KatList[1].description}
                            title={KatList[1].title}
                        />
                    )}

                    {props.description !== CategoryDescription.COOLANT && (
                        <CategoryListTeaser
                            url={KatList[2].url}
                            image={KatList[2].image}
                            description={KatList[2].description}
                            title={KatList[2].title}
                        />
                    )}

                    {(props.description === CategoryDescription.ENGINEOIL ||
                        props.description === CategoryDescription.GEAROIL ||
                        props.description === CategoryDescription.COOLANT) && (
                        <CategoryListTeaser
                            url={KatList[3].url}
                            image={KatList[3].image}
                            description={KatList[3].description}
                            title={KatList[3].title}
                        />
                    )}
                </WbGridRow>
            </WbGrid>
        </div>
    );
}
