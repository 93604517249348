import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { TextField, Theme } from '@mui/material';
import { WbButton, WbIcon, WbText } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { blue, white, red } from '@mercedes-benz/mui5-theme';

const useStyles = makeStyles()((_theme: Theme) => ({
    searchContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: '100%',
    },
    searchInput: {
        backgroundColor: white,
    },
    blueColor: {
        color: blue[60],
    },
    whiteColor: {
        color: white,
    },
    redColor: {
        color: red[50],
    },
    textFieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
}));

export interface SearchFieldProps {
    onChangeValue: (value: string) => void;
    label?: string;
    error?: boolean;
    delay?: number;
    maxLength?: number;
    upperCase?: boolean;
    onSearchClick?: () => void;
    isSearching?: boolean;
    isSearchDisabled?: boolean;
    errorMessage?: string;
    hintMessage?: string;
}

export const SearchField = forwardRef<HTMLElement, SearchFieldProps>((props, ref) => {
    const [inputValue, setInputValue] = useState<string>('');
    const DELAY_MILLI_SECONDS = props.delay ?? 1000; //  1 second
    const { classes } = useStyles();
    const timer = useRef<NodeJS.Timeout>(undefined);

    const handleDelayedSearch = (query: string) => {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            props.onChangeValue(query);
        }, DELAY_MILLI_SECONDS);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const query = event.target.value;
        if (query) {
            setInputValue(query);
            handleDelayedSearch(query);
        }
    };

    const handleClear = () => {
        setInputValue('');
        props.onChangeValue('');
    };

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.searchContainer}>
            <div className={classes.textFieldContainer}>
                <TextField
                    label={props.label}
                    variant="filled"
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handleInputChange(e);
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    value={inputValue}
                    type="text"
                    error={props.error}
                    slotProps={{
                        htmlInput: {
                            style: { textTransform: props.upperCase ? 'uppercase' : 'none' },
                            maxLength: props.maxLength,
                        },
                        input: {
                            endAdornment: inputValue && (
                                <WbIcon name="bds/close/24" onClick={handleClear} style={{ cursor: 'pointer' }} />
                            ),
                        },
                    }}
                    className={classes.searchInput}
                    inputRef={ref}
                />
                <WbText className={props.errorMessage ? classes.redColor : classes.whiteColor}>
                    {props.errorMessage ? props.errorMessage : props.hintMessage}
                </WbText>
            </div>
            <WbButton
                variant="primary"
                iconOnly
                loading={props.isSearching ? true : undefined}
                disabled={props.isSearchDisabled || undefined}
                onClick={(event) => {
                    event.stopPropagation();
                    props.onSearchClick?.();
                }}
            >
                <WbIcon name="bds/search/24" />
            </WbButton>
        </div>
    );
});
