import { WbGrid, WbGridCol, WbGridRow, WbHeading, WbSlider, WbSliderItem } from '@workbench/react';
import CategorySliderCard from './CategorySliderCard';
import { CategoryType, CATEGORY_PROPS } from '../../../../common/Constants';
import { makeStyles } from 'tss-react/mui';
import { MediaQuery, MediaQueryChangeEvent, MediaQueryService } from '@workbench/core';
import { useEffect, useState } from 'react';
import { useTranslation } from '../../../../common/i18n/useTranslation';
import { white } from '@mercedes-benz/mui5-theme';

const myMediaQueryService = new MediaQueryService(window);

const useStyles = makeStyles()(() => ({
    title: {
        color: white,
    },
    slider: {
        width: '100%',
        margin: 'auto',
        '@media (max-width: 767px)': {
            '& wb-slider-item': {
                width: '100%',
            },
        },
    },
}));

export default function CategorySlider() {
    const currentMediaQuery: MediaQuery = myMediaQueryService.getCurrentMediaQuery();
    const content: CategoryType[] = CATEGORY_PROPS;
    const { classes } = useStyles();
    const translate = useTranslation();

    const handleShowArrowNavigation = (mq: MediaQueryChangeEvent['current']): boolean => {
        if (mq === 'mq1' || mq === 'mq2') {
            return false;
        }
        return true;
    };

    const [showArrowNavigation, setShowArrowNavigation] = useState(handleShowArrowNavigation(currentMediaQuery));

    useEffect(() => {
        const handleResize = (e: CustomEvent<MediaQueryChangeEvent>) => {
            setShowArrowNavigation(handleShowArrowNavigation(e.detail.current));
        };

        window.addEventListener('wbresize', ((e: CustomEvent<MediaQueryChangeEvent>) => {
            handleResize(e);
        }) as EventListener);

        return function cleanupListener() {
            window.removeEventListener('resize', ((e: CustomEvent<MediaQueryChangeEvent>) => {
                handleResize(e);
            }) as EventListener);
        };
    }, []);

    return (
        <WbGrid>
            <WbGridRow>
                <WbGridCol mq1={12}>
                    <WbHeading size="m" className={classes.title}>
                        {translate('MainPageSliderHeader')}
                    </WbHeading>
                </WbGridCol>
                <WbGridCol mq1={12}>
                    <WbSlider
                        className={classes.slider}
                        gridGutter
                        theme="dark"
                        showArrowNavigation={showArrowNavigation}
                        showDotNavigation
                        nextButtonAriaLabel="Next"
                        previousButtonAriaLabel="Previous"
                        scrollSnap
                    >
                        {content.map((element) => {
                            return (
                                <WbSliderItem key={element.description}>
                                    <CategorySliderCard category={element} />
                                </WbSliderItem>
                            );
                        })}
                    </WbSlider>
                </WbGridCol>
            </WbGridRow>
        </WbGrid>
    );
}
