import { useEffect, useState } from 'react';
import { useCategoryMenuContext } from '../context/CategoryContext';
import { WbButton, WbText, WbGrid } from '@workbench/react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CategoryDescription } from '../../../common/Constants';
import { useTranslationContext } from '../../../common/i18n/TranslationProvider';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { FormattedMessage } from 'react-intl';
import { white } from '@mercedes-benz/mui5-theme';

const useStyles = makeStyles()((theme: Theme) => ({
    showMore: {
        marginTop: theme.spacing(4),
    },
    prologText: {
        color: white,
        whiteSpace: 'pre-line',
        '@media (max-width: 767px)': {
            margin: 'auto',
        },
        '@media (min-width: 768px) and (max-width: 993px)': {
            margin: 'auto',
        },
    },
    prologContainer: {
        overflow: 'hidden',
    },
    prologTextTeaser: {
        height: theme.spacing(30),
    },
    prologTransition: {
        width: '100%',
        background: '-webkit-linear-gradient(white, white, white, #333)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        '&:after': {
            background: 'linear-gradient(to top, #000000, #000000, #000000, #00000000), no-repeat',
        },
    },
}));

interface Message {
    id: string;
    defaultMessage: string;
}

interface PrologProps {
    description: CategoryDescription;
}

export default function Prolog(props: PrologProps) {
    const { classes } = useStyles();
    const [expand, setExpand] = useState(false);
    const [text, setText] = useState<Message | null>(null);

    const translate = useTranslation();
    const { langState } = useTranslationContext();

    const { categorySheetCollection, hasCategorySheetsItems } = useCategoryMenuContext();

    const messages = {
        PrologMotorOil: {
            id: 'PrologMotorOil',
            defaultMessage: translate('PrologMotorOil'),
        } as Message,
        PrologFuel: {
            id: 'PrologFuel',
            defaultMessage: translate('PrologFuel'),
        } as Message,
        PrologGearOil: {
            id: 'PrologGearOil',
            defaultMessage: translate('PrologGearOil'),
        } as Message,
        PrologAdBlue: {
            id: 'PrologAdBlue',
            defaultMessage: translate('PrologAdBlue'),
        } as Message,
        PrologCoolant: {
            id: 'PrologCoolant',
            defaultMessage: translate('PrologCoolant'),
        } as Message,
        PrologBrakeFluid: {
            id: 'PrologBrakeFluid',
            defaultMessage: translate('PrologBrakeFluid'),
        } as Message,
    };

    useEffect(() => {
        setExpand(!hasCategorySheetsItems());
        // Update the document title using the browser API
        generateText(props.description);
    }, [langState, props.description, categorySheetCollection]);

    const generateText = (description: string) => {
        switch (description) {
            case CategoryDescription.ENGINEOIL:
                setText(messages.PrologMotorOil);
                break;
            case CategoryDescription.FUEL:
                setText(messages.PrologFuel);
                break;
            case CategoryDescription.GEAROIL:
                setText(messages.PrologGearOil);
                break;
            case CategoryDescription.ADBLUE:
                setText(messages.PrologAdBlue);
                break;
            case CategoryDescription.COOLANT:
                setText(messages.PrologCoolant);
                break;
            case CategoryDescription.BRAKEFLUID:
                setText(messages.PrologBrakeFluid);
                break;
            default:
                setText(null);
                break;
        }
        return text;
    };

    return (
        <WbGrid>
            {text && (
                <>
                    <div
                        className={`${classes.prologContainer} ${!expand ? classes.prologTextTeaser : ''} ${
                            !expand ? classes.prologTransition : ''
                        }`}
                    >
                        <WbText size="l" className={classes.prologText}>
                            {text && <FormattedMessage {...text} values={{ br: <br /> }} />}
                        </WbText>
                    </div>

                    <WbButton
                        className={classes.showMore}
                        variant="secondary"
                        theme="dark"
                        onClick={() => setExpand(!expand)}
                    >
                        {expand ? translate('ShowLess') : translate('ShowMore')}
                    </WbButton>
                </>
            )}
        </WbGrid>
    );
}
