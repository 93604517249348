import { WbGrid, WbHeading, WbText } from '@workbench/react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { white } from '@mercedes-benz/mui5-theme';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'grid',
        gap: theme.spacing(2),
        color: white,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
}));

export default function ComingSoon() {
    const { classes } = useStyles();

    return (
        <WbGrid className={classes.container}>
            <WbHeading size="l">Coming Soon</WbHeading>
            <WbText size="m">This page is under construction.</WbText>
        </WbGrid>
    );
}
