import { WbButton, WbCheckbox, WbLink, WbModal, WbModalLevel, WbText } from '@workbench/react';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { WbCheckboxCustomEvent } from '@workbench/core';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { useTranslationContext } from '../../../common/i18n/TranslationProvider';
import { SupportedLanguages } from '../../../common/i18n/SupportedLanguages';

const useStyles = makeStyles()((theme: Theme) => ({
    languageContainer: {
        display: 'flex',
        margin: theme.spacing(2),
        justifyContent: 'flex-end',
        gap: theme.spacing(0.5),
    },
    checkboxActionsContainer: {
        display: 'flex',
        gap: theme.spacing(1),
    },
    cookieLayerFooter: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'initial',
        width: '100%',
        marginInlineStart: theme.spacing(1),
        '& wb-button': {
            width: '100%',
        },
        '& .row:nth-of-type(1)': {
            display: 'flex',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            gap: theme.spacing(1),
        },
        '& .row:nth-of-type(2)': {
            textAlign: 'right',
        },
        '@media (min-width: 768px)': {
            marginLeft: 'auto',
            gap: theme.spacing(2),
            width: 'initial',
            '& wb-button': {
                width: 'initial',
            },
            '& wb-button:last-child': {
                marginBottom: 0,
            },
            '&row:nth-of-type(1)': {
                gap: theme.spacing(2),
            },
        },
    },
}));

interface CookieBarProps {
    showFooterModal: () => void;
    hideCookieBar: () => void;
}

export default function CookieBar(props: CookieBarProps) {
    const { classes } = useStyles();
    const { updateLangState } = useTranslationContext();
    const translate = useTranslation();
    const [isTracking, setIsTracking] = useState<boolean>(false);

    const setLanguageDe = () => {
        updateLangState(SupportedLanguages.de);
    };

    const setLanguageEn = () => {
        updateLangState(SupportedLanguages.en);
    };

    const toggleTracking = (isTrackingValue: boolean) => {
        setIsTracking(isTrackingValue);
    };

    const hideCookieBar = () => {
        props.hideCookieBar();
    };

    const agreeAll = () => {
        Cookies.set('c_disclaimer', 'necessary', { expires: 365 });
        Cookies.set('c_track_data', 'true', { expires: 365 });
        hideCookieBar();
    };

    const saveSelection = () => {
        Cookies.set('c_disclaimer', 'necessary', { expires: 365 });
        if (isTracking === true) {
            Cookies.set('c_track_data', 'true', { expires: 365 });
        } else {
            Cookies.remove('c_track_data');
        }
        hideCookieBar();
    };

    const showDetails = () => {
        props.showFooterModal();
    };

    return (
        <WbModal variant="prompt" scroll-lock active-level="1" modalAriaLabel="CookieBar">
            <div className={classes.languageContainer}>
                <WbLink onClick={setLanguageDe} variant="small">
                    DE
                </WbLink>
                <span> | </span>
                <WbLink onClick={setLanguageEn} variant="small">
                    EN
                </WbLink>
            </div>

            <WbModalLevel
                level={1}
                headline={translate('CookieBarHeader')}
                showBackButton={false}
                showCloseButton={false}
                backButtonLabel="Back"
                closeButtonLabel="Close"
            >
                <WbText size="m">{translate('CookieBarContent')}</WbText>
                <br />
                <div className={classes.checkboxActionsContainer}>
                    <WbCheckbox name="newsletter" disabled checked={true}>
                        {translate('TechnicallyNeccessary')}
                    </WbCheckbox>
                    <WbCheckbox
                        name="newsletter"
                        checked={isTracking}
                        onWbchange={(
                            e: WbCheckboxCustomEvent<{
                                name: string;
                                value: string;
                                checked: boolean;
                            }>
                        ) => toggleTracking(e.detail.checked)}
                    >
                        {translate('Analytics')}
                    </WbCheckbox>
                </div>
                <br />
                <div style={{ display: 'inline-block' }}>
                    <WbLink onClick={showDetails} variant="inline">
                        {translate('CookieBarDetails')}
                    </WbLink>
                </div>
                <br />
                <div slot="footer" className={classes.cookieLayerFooter}>
                    <div className="row">
                        <WbButton onClick={saveSelection} type="button" variant="primary">
                            {translate('SaveSelected')}
                        </WbButton>
                        <WbButton onClick={agreeAll} type="button" variant="primary">
                            {translate('AgreeToAll')}
                        </WbButton>
                    </div>
                </div>
            </WbModalLevel>
        </WbModal>
    );
}
