import {
    WbButton,
    WbCard,
    WbGrid,
    WbGridCol,
    WbGridRow,
    WbHeading,
    WbIcon,
    WbSpinner,
    WbText,
    WbVerticalScroll,
} from '@workbench/react';
import { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Dialog, DialogContent, DialogTitle, Theme, alpha } from '@mui/material';
import { white, black, grey } from '@mercedes-benz/mui5-theme';
import { getSearchResults } from '../../../../../utility/FetchAPI';
import { useTranslation } from '../../../../../common/i18n/useTranslation';
import { SupportedLanguages } from '../../../../../common/i18n/SupportedLanguages';
import { useTranslationContext } from '../../../../../common/i18n/TranslationProvider';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { IProductSheet } from '../../../../../interfaces/IProductSheet';
import { SearchField } from '../../../components/SearchField';

const useStyles = makeStyles()((theme: Theme) => ({
    modal: {
        zIndex: 1350,
        '& .MuiDialog-paper': {
            backgroundColor: alpha(black, 0.7),
        },
    },
    gridRow: {
        gridGap: `${theme.spacing(0)} !important`,
    },
    textField: {
        backgroundColor: white,
    },
    closeButton: {
        height: '100%',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        paddingBottom: theme.spacing(6),
    },
    closeIcon: {
        color: white,
    },
    searchButton: {
        height: '100%',
    },
    searchResultCard: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        border: `${theme.spacing(0.1)} solid`,
    },
    searchResultRow: {
        padding: theme.spacing(2),
    },
    searchResultTitle: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(1),
    },
    searchResultText: {
        color: white,
    },
    sheetNumberText: {
        color: white,
    },
    productTitleText: {
        color: white,
        marginTop: theme.spacing(2),
        width: '100%',
    },
    verticalScroll: {
        ['--gradient-start-color' as string]: 'none',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(0.1),
        overflow: 'auto !important',
    },
    verticalLine: {
        height: '100%',
        borderLeft: '1px solid #0000',
        borderColor: grey[50],
        display: 'none',
        '@media (min-width: 780px)': {
            display: 'flex',
        },
    },
    descriptionColumn: {
        marginLeft: theme.spacing(2),
        '@media (min-width: 780px)': {
            marginLeft: theme.spacing(0),
        },
    },
    cardIcon: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        color: white,
    },
    spinnerRow: {
        textAlign: 'center',
        marginTop: theme.spacing(5),
    },
    spinner: {
        height: theme.spacing(10),
        width: theme.spacing(10),
    },
}));

interface SearchModalProps {
    open: boolean;
    handleOpen: () => void;
    handleClose: () => void;
}

const SearchModal = (props: SearchModalProps) => {
    const translate = useTranslation();
    const { classes } = useStyles();
    const inputRef = useRef<HTMLElement>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [searchResult, setSearchResult] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { langState } = useTranslationContext();
    let navigate = useNavigate();

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (props.open && inputRef.current) {
                inputRef.current.focus();
            }
        }, 500);

        return () => {
            clearTimeout(timeout);
            setSearchText('');
            setSearchResult(null);
        };
    }, [props]);

    useEffect(() => {
        setSearchResult(null);
    }, [searchText]);

    const handleSearchButtonClick = () => {
        if (searchText?.length > 0) {
            getSearchResults(searchText).then((results) => {
                setSearchResult(results.data);
            });
        }
    };

    const handleSearchTextChange = (text: string) => {
        setSearchText(text);

        if (text?.length > 0) {
            setIsLoading(true);
            getSearchResults(text).then(
                (results) => {
                    setSearchResult(results.data);
                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                }
            );
        }
    };

    const selectSheetItem = (sheet: IProductSheet) => {
        props.handleClose();
        if (sheet.pdfLinkDe === null || sheet.pdfLinkDe === '' || sheet.pdfLinkEn === null || sheet.pdfLinkEn === '') {
            setSearchText('');
            setSearchResult(null);
        }
        navigate(`/sheet/${sheet?.sheetNumber}`);
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog id="dialog" className={classes.modal} open={props.open} onClick={() => handleClose()} fullScreen>
            <DialogTitle>
                <WbGrid>
                    <WbGridRow>
                        <WbGridCol mq1={11}></WbGridCol>
                        <WbGridCol mq1={1}>
                            <WbButton
                                className={classes.closeButton}
                                variant="tertiary"
                                size="l"
                                onClick={() => handleClose()}
                            >
                                <WbIcon className={classes.closeIcon} name="close" />
                            </WbButton>
                        </WbGridCol>
                    </WbGridRow>
                    <WbGridRow className={classes.gridRow}>
                        <WbGridCol mq1={12}>
                            <SearchField
                                label={translate('SearchLabel')}
                                ref={inputRef}
                                onChangeValue={handleSearchTextChange}
                                onSearchClick={handleSearchButtonClick}
                                isSearching={isLoading}
                            />
                        </WbGridCol>
                    </WbGridRow>
                    {isLoading ? (
                        <WbGridRow className={classes.spinnerRow}>
                            <WbGridCol mq1={12}>
                                <WbSpinner className={classes.spinner} theme="light" />
                            </WbGridCol>
                        </WbGridRow>
                    ) : (
                        searchText !== '' && (
                            <WbGridRow className={classes.searchResultTitle}>
                                <WbGridCol mq1={12}>
                                    <WbText className={classes.searchResultText} size="m" strong>
                                        <FormattedMessage
                                            id="SearchResult"
                                            defaultMessage="SearchResult"
                                            values={{
                                                resultCount:
                                                    searchResult?.length === 0 ? translate('No') : searchResult?.length,
                                            }}
                                        />
                                    </WbText>
                                </WbGridCol>
                            </WbGridRow>
                        )
                    )}
                </WbGrid>
            </DialogTitle>
            <DialogContent>
                <WbGrid>
                    <WbVerticalScroll className={classes.verticalScroll}>
                        <div className={classes.container}>
                            {searchResult?.map((productSheet: any) => {
                                return (
                                    <WbCard
                                        key={productSheet?.sheetNumber}
                                        className={classes.searchResultCard}
                                        interactive
                                        variant="dark-grey"
                                        onClick={() => selectSheetItem(productSheet)}
                                    >
                                        <WbGrid>
                                            <WbGridRow className={classes.searchResultRow}>
                                                <WbGridCol mq1={2}>
                                                    <WbText size="s">{translate('Sheet')}</WbText>
                                                    <WbHeading className={classes.sheetNumberText} size="m">
                                                        {productSheet?.sheetNumber}
                                                    </WbHeading>
                                                </WbGridCol>
                                                <WbGridCol mq1={1}>
                                                    <div className={classes.verticalLine}></div>
                                                </WbGridCol>
                                                <WbGridCol mq1={8} className={classes.descriptionColumn}>
                                                    <WbText size="s">{translate('Description')}</WbText>
                                                    <WbText className={classes.productTitleText} size="l">
                                                        {langState === SupportedLanguages.de
                                                            ? productSheet?.titleDe
                                                            : productSheet?.titleEn}
                                                    </WbText>
                                                </WbGridCol>
                                                <WbGridCol mq1={1} className={classes.cardIcon}>
                                                    <WbIcon name="chevron-right"></WbIcon>
                                                </WbGridCol>
                                            </WbGridRow>
                                        </WbGrid>
                                    </WbCard>
                                );
                            })}
                        </div>
                    </WbVerticalScroll>
                </WbGrid>
            </DialogContent>
        </Dialog>
    );
};

export default SearchModal;
