import { WbGridCol, WbGridRow, WbIcon, WbText } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { green, red, white } from '@mercedes-benz/mui5-theme';

const useStyles = makeStyles<{ hasContent: boolean }>()((theme: Theme, { hasContent }) => ({
    gridRow: {
        padding: theme.spacing(2),
        color: white,
        background: hasContent ? green[50] : red[50],
        borderRadius: theme.spacing(1),
        alignItems: 'center',
    },
    icon: {
        ['--size' as string]: theme.spacing(4.5),
    },
}));

interface SearchResultNotificationProps {
    hasContent: boolean;
}

const SearchResultNotification = ({ hasContent }: SearchResultNotificationProps) => {
    const { classes } = useStyles({ hasContent });
    const translate = useTranslation();

    return (
        <>
            <WbGridRow className={classes.gridRow}>
                <WbGridCol mq1={1} mq2={1} mq3={1} mq4={1} mq5={1} mq6={1}>
                    <WbIcon className={classes.icon} name={hasContent ? 'bds/checkmark-circle/24' : 'bds/warning/24'} />
                </WbGridCol>
                <WbGridCol mq1={11} mq2={11} mq3={11} mq4={11} mq5={11} mq6={11}>
                    <WbText>
                        {hasContent
                            ? translate('FluidFinderFinSearchResultNotificationSuccess')
                            : translate('FluidFinderFinSearchResultNotificationError')}
                    </WbText>
                </WbGridCol>
            </WbGridRow>
            {!hasContent && (
                <WbGridRow>
                    <div style={{ height: '250px' }} />
                </WbGridRow>
            )}
        </>
    );
};

export default SearchResultNotification;
