import { useEffect } from 'react';
import { getSheetNumbersByCategory } from '../../../utility/FetchAPI';
import { WbButton, WbGrid, WbIcon, WbLink, WbTable, WbTableCell, WbTableHeader, WbTableRow } from '@workbench/react';
import { IProductSheet } from '../../../interfaces/IProductSheet';
import { useCategoryMenuContext } from '../context/CategoryContext';
import { CategoryDescription } from '../../../common/Constants';
import { makeStyles } from 'tss-react/mui';
import { useTranslationContext } from '../../../common/i18n/TranslationProvider';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { SupportedLanguages } from '../../../common/i18n/SupportedLanguages';
import { black } from '@mercedes-benz/mui5-theme';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()(() => ({
    tableContainer: {
        background: black,
        width: '100%',
        '@media (min-width: 992px)': {
            width: '56%',
        },
    },
}));

interface CategoryTableProps {
    description: CategoryDescription;
}

export function CategoryTable(props: CategoryTableProps) {
    const { langState } = useTranslationContext();
    const translate = useTranslation();
    const { classes } = useStyles();
    const { categorySheetCollection, updateCategorySheets } = useCategoryMenuContext();
    const navigate = useNavigate();

    useEffect(() => {
        const description: string = props.description;
        getSheetNumbersByCategory(description).then((result: any) => {
            updateCategorySheets(result.data);
        });
    }, [props.description]);

    const navigateToSheet = (sheetNumber: string) => {
        navigate(`/sheet/${sheetNumber}`);
    };

    return (
        <WbGrid>
            {categorySheetCollection.length > 0 && (
                <div className={classes.tableContainer}>
                    <WbTable striped theme="dark">
                        <WbTableRow>
                            <WbTableHeader>#</WbTableHeader>
                            <WbTableHeader>{translate('Sheet')}</WbTableHeader>
                            <WbTableHeader>{translate('Description')}</WbTableHeader>
                            <WbTableHeader></WbTableHeader>
                        </WbTableRow>
                        {categorySheetCollection.map((sheet: IProductSheet, index) => {
                            return (
                                <WbTableRow key={sheet.sheetNumber + sheet.category}>
                                    <WbTableHeader>{index + 1}</WbTableHeader>
                                    <WbTableCell>
                                        <WbLink
                                            variant="standalone"
                                            theme="dark"
                                            onClick={() => navigateToSheet(sheet.sheetNumber)}
                                        >
                                            {sheet.sheetNumber}
                                        </WbLink>
                                    </WbTableCell>
                                    <WbTableCell>
                                        <WbLink
                                            variant="standalone"
                                            theme="dark"
                                            onClick={() => navigateToSheet(sheet.sheetNumber)}
                                        >
                                            {langState === SupportedLanguages.de ? sheet.titleDe : sheet.titleEn}
                                        </WbLink>
                                    </WbTableCell>
                                    <WbTableCell>
                                        <WbButton
                                            variant="tertiary"
                                            size="s"
                                            onClick={() => navigateToSheet(sheet.sheetNumber)}
                                        >
                                            <WbIcon name="bds/chevron-right/24" aria-hidden="true"></WbIcon>
                                        </WbButton>
                                    </WbTableCell>
                                </WbTableRow>
                            );
                        })}
                    </WbTable>
                </div>
            )}
        </WbGrid>
    );
}
