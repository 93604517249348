import { useState, useEffect } from 'react';
import { WbIcon, WbTable, WbTableCell, WbTableHeader, WbTableRow } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { IProductSheet } from '../../../interfaces/IProductSheet';
import { TABLE_HEADER } from '../../../common/Constants';
import { Theme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../common/i18n/useTranslation';
import I18nTexts from '../../../common/i18n/texts/I18nTexts';
import { white, black, blue } from '@mercedes-benz/mui5-theme';
import { useTranslationContext } from '../../../common/i18n/TranslationProvider';

const useStyles = makeStyles()((theme: Theme) => ({
    sheetTable: {
        width: '100%',
        overflow: 'auto',
        maxHeight: theme.spacing(100),
        backgroundColor: white,
        '@media (max-width: 767px)': {
            width: '100%',
            height: 'fit-content',
            display: 'block',
            overflowX: 'auto',
        },
    },
    tableHeader: {
        position: 'sticky',
        top: 0,
        background: white,
    },
    iconCheckmark: {
        color: blue[50],
    },
    link: {
        textDecoration: 'none',
        color: black,
    },
}));

interface SheetTableProps {
    sheet: IProductSheet;
}

export function SheetTable(props: SheetTableProps) {
    const { classes } = useStyles();
    const translate = useTranslation();
    const [sheet, setSheet] = useState<IProductSheet>();
    const { langState } = useTranslationContext();

    useEffect(() => {
        if (props.sheet?.products !== null) {
            props.sheet.products.forEach((product) => {
                if (props.sheet?.privateLabels !== null) {
                    props.sheet.privateLabels.find((privateLabel) => {
                        if (product?.productName === privateLabel?.name) {
                            product.link = privateLabel?.link;
                            return true;
                        }
                        return false;
                    });
                }
            });
        }

        setSheet(props.sheet);

        document?.getElementById('sheetTableId')?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [props.sheet, langState]);

    return (
        <>
            {sheet && sheet?.products?.length > 0 && (
                <div id="sheetTableId">
                    <WbTable striped className={classes.sheetTable}>
                        <WbTableRow className={classes.tableHeader}>
                            {sheet?.products?.some((i) => i?.productName !== null) && (
                                <WbTableHeader>
                                    {translate(TABLE_HEADER['productName'].value as keyof I18nTexts)}
                                </WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type30 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type30'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type40 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type40'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type80 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type80'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type90 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type90'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type0W !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type0W'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type0W20 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type0W20'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type0W30 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type0W30'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type0W40 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type0W40'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type5W20 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type5W20'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type5W30 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type5W30'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type5W40 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type5W40'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type5W50 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type5W50'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type10W30 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type10W30'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type10W40 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type10W40'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type10W60 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type10W60'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type15W40 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type15W40'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type20W50 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type20W50'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i.type75W !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type75W'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type75W80 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type75W80'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type75W85 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type75W85'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type75W90 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type75W90'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i.type80W !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type80W'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type80W90 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type80W90'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type80W85 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type80W85'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.type85W90 !== false) && (
                                <WbTableHeader>{TABLE_HEADER['type85W90'].value}</WbTableHeader>
                            )}
                            {sheet?.products?.some((i) => i?.description !== null) && (
                                <WbTableHeader>
                                    {translate(TABLE_HEADER['description'].value as keyof I18nTexts)}
                                </WbTableHeader>
                            )}
                        </WbTableRow>
                        {sheet?.products?.map((row) => {
                            return (
                                <WbTableRow key={row.productId + row.productName}>
                                    {row?.link !== null ? (
                                        <WbTableCell>
                                            <Link className={classes.link} to={row?.link} target="_blank">
                                                {row?.productName}
                                            </Link>
                                        </WbTableCell>
                                    ) : (
                                        <WbTableCell>{row?.productName}</WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type30 !== false) && (
                                        <WbTableCell>
                                            {row?.type30 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type40 !== false) && (
                                        <WbTableCell>
                                            {row?.type40 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type80 !== false) && (
                                        <WbTableCell>
                                            {row?.type80 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type90 !== false) && (
                                        <WbTableCell>
                                            {row?.type90 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type0W !== false) && (
                                        <WbTableCell>
                                            {row?.type0W && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type0W20 !== false) && (
                                        <WbTableCell>
                                            {row?.type0W20 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type0W30 !== false) && (
                                        <WbTableCell>
                                            {row?.type0W30 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type0W40 !== false) && (
                                        <WbTableCell>
                                            {row?.type0W40 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type5W20 !== false) && (
                                        <WbTableCell>
                                            {row?.type5W20 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type5W30 !== false) && (
                                        <WbTableCell>
                                            {row?.type5W30 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type5W40 !== false) && (
                                        <WbTableCell>
                                            {row?.type5W40 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type5W50 !== false) && (
                                        <WbTableCell>
                                            {row?.type5W50 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type10W30 !== false) && (
                                        <WbTableCell>
                                            {row?.type10W30 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type10W40 !== false) && (
                                        <WbTableCell>
                                            {row?.type10W40 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type10W60 !== false) && (
                                        <WbTableCell>
                                            {row?.type10W60 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type15W40 !== false) && (
                                        <WbTableCell>
                                            {row?.type15W40 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type20W50 !== false) && (
                                        <WbTableCell>
                                            {row?.type20W50 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type75W !== false) && (
                                        <WbTableCell>
                                            {row?.type75W && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type75W80 !== false) && (
                                        <WbTableCell>
                                            {row?.type75W80 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type75W85 !== false) && (
                                        <WbTableCell>
                                            {row?.type75W85 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type75W90 !== false) && (
                                        <WbTableCell>
                                            {row?.type75W90 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type80W !== false) && (
                                        <WbTableCell>
                                            {row?.type80W && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type80W90 !== false) && (
                                        <WbTableCell>
                                            {row?.type80W90 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type80W85 !== false) && (
                                        <WbTableCell>
                                            {row?.type80W85 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    {sheet?.products?.some((i) => i?.type85W90 !== false) && (
                                        <WbTableCell>
                                            {row?.type85W90 && (
                                                <WbIcon name="checkmark" className={classes.iconCheckmark} />
                                            )}
                                        </WbTableCell>
                                    )}
                                    <WbTableCell>{row?.description}</WbTableCell>
                                </WbTableRow>
                            );
                        })}
                    </WbTable>
                </div>
            )}
        </>
    );
}
